import { careerCoachingData, germanCoursesData } from "../../data/dummyData";
import { Helmet } from "react-helmet-async";
import React, {useEffect} from "react";
import AcademyLandingPage from "./AcademyLanding";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import CoursesSection from "./CoursesSection";
import FooterPage from "./Footer";
import SEO from "components/seo/SEO";
import GermanLanguagePage from "pages/home/GermanLanguage";
import prototypeIllustrationImageSrc from "images/work.svg"
//eslint-disable-next-line
import tw from "twin.macro";

const Container = tw.section`mt-20 relative`;

function AcademyPage() {

  useEffect(() => {
    document.title = "Weg2De Akademie - Learn German Online";
  }, []);
  return (
    <AnimationRevealPage>
       <SEO
        title="Weg2De Akademie - Learn German Online"
        description="At Weg2De Akademie, we offer customized German language and orientation courses designed to meet your unique needs. Whether you're preparing to study, work, or live in a German-speaking country, our courses will equip you with the language skills and cultural understanding necessary for a successful transition."
        name="Weg2De Akademie - Learn German Online"
        keyword="Learn German Online in Germany,Learn German from home - Weg2De Akademie, Affordable Online German Schools in Germany - Weg2De Akademie, Best Online German School - Weg2De Akademie, German Individual Courses, German for Professionals, German Online Group Course, Best Online German School, Weg2De Akademie"
        type=""
      />
      <Helmet>
        <title>Weg2De Akademie - Learn German Online </title>
        <meta property="og:title" content="Weg2De Akademie - Learn German Online" />
        <meta property="og:description" content="At Weg2De Akademie, we offer customized German language and orientation courses designed to meet your unique needs. Whether you're preparing to study, work, or live in a German-speaking country, our courses will equip you with the language skills and cultural understanding necessary for a successful transition." />
        <meta property="og:url" content="https://weg2de.com/akademie" />
      </Helmet>
      <Container>
          <AcademyLandingPage />
          <CoursesSection
            data={germanCoursesData}
            heading="German Courses"
            description="Discover German Language and Orientation Courses tailored to your
          specific needs at Weg2De Akademie!"
          />
          <CoursesSection
            data={careerCoachingData}
            heading="Career Coaching"
            description="There will be career coaching description here"
          />

        <GermanLanguagePage
        subheading="Book a discovery Call"
        heading="Want to relocate to Germany?"
        description="Are you looking to move to Germany and need help choosing the right pathway for you? Book our discovery call to get an insight into available opportunities in Germany"
        primaryButtonText="Discovery Call"
        primaryButtonUrl="https://termin.weg2de.com/#/en-de"
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={true}
      />
      </Container>
      <FooterPage
      />
    </AnimationRevealPage>
  );
}

export default AcademyPage;
