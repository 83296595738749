import AdmissionIcon from "../images/uni-admission.svg";
import ApIcon from "../images/job-search.svg";
import CandidateSearchIcon from "../images/candidate-search.svg";
import CareerIMG from "../images/job-search.svg";
import EntwicklerIcon from "../images/webdev.svg";
import GermanyIcon from "../images/volunteer.svg";
import { HiBadgeCheck } from "react-icons/hi";
import { HighlightedText } from "components/misc/Headings";
import Bisola from "../images/bisola.jpg";
//import IMG2 from "../images/b.png";
import IndividualIMG from "../images/co-work.svg";
import IshaqIMG from "../images/ishaq.webp";
//import KfzIcon from "../images/mechanic.svg";
import MigrationIcon from "../images/migration.svg";
//import NurseIcon from "../images/nurse.svg";
import OnlineGermanIcon from "../images/online-german.svg";
import OnlineLearningIMG from "../images/online_learning.svg";
import ProfessionalIMG from "../images/professional.svg";
import React from "react";
import RelocationIcon from "../images/german-courses.svg";
//import SchulabsolventIcon from "../images/carpenter.svg";
import TravelIcon from "../images/travel.svg";
import VisaIcon from "../images/Approved-Visa.svg";
import WorkIcon from "../images/ausbildung-prog.svg";
import JoshCert from "../images/rev2.png";
import BisolaCert from "../images/rev1.png";
import Naya from "../images/naya.jpg";
import Lima from "../images/lima.jpg";
import Luneburg from "../images/luneburg.jpg";
import Erfurt from "../images/erfurt.webp";
//import Hosnny from "../images/Hossny1.png"
//import Allaa from "../images/Allaa.png"
//import Grace from "../images/grace.jpg"
//import { SectionDescription } from "components/misc/Typography";
//import { SectionHeading } from "components/misc/Headings";
import tw from "twin.macro";
//import { LuImage } from "react-icons/lu";
//import { SectionDescription } from "components/misc/Typography";
//const LeftColumn = tw.section`md:pr-5 relative flex-1`;
//const RightColumn = tw.section`md:pl-5 relative flex-1`;
//const TwoColumn = tw.section`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto items-center `;
const IconWrapper = tw.div` flex items-center text-primary-500`;
const ListItem = tw.li`text-primary-500 text-left  text-sm md:text-base lg:text-lg font-light mb-3`;
const TextItem = tw.p`text-gray-500`;
const Icon = tw.div`hidden md:block mr-2 `;
const ListWrapper = tw.ul`list-disc md:list-none text-primary-500`;
const customAns = (
  <>
    If you have more questions or need assistance, please contact us at{" "}
    <HighlightedText>info@weg2de.com</HighlightedText> or WhatsApp{" "}
    <HighlightedText>+4917666572214</HighlightedText>
  </>
);

export const WhatWeDoData = [

  {
    imageSrc: CandidateSearchIcon,
    linkTo: "/for-companies",
    to: "for-companies",
    title: `HR Consulting & Employee Relocation`,
    description:
      "Weg2De is committed to helping you and your employees settle in with ease. Hiring internationally can be challenging, but with the right guidance, it can also be highly rewarding. Our HR consulting services are designed to empower your team with Best practices in Recruiting International Talent & understandng immigration and Residency for Non-EU hires e.g Beschleunigtes Fachkräfteverfahren, Work permit.",
  },
  {
    imageSrc: MigrationIcon,
    linkTo: "/for-individuals",
    to: "for-individuals",
    title: "Individual Immigration & Relocation Services",
    description:
      "Relocating and Starting a life in a new country involves navigating complex immigration processes. Our team of experts is here to simplify this journey for you. Our services include: Visa and Work Permit Support, Family re-union, flight and hotel reservation, travel health insurance, airport pick up, Finding Temporary and Long-Term Housing, Address Registration, Residence Permit, Health Insurance, School and Childcare Support etc.",
  },

  {
    imageSrc: OnlineGermanIcon,
    linkTo: "/akademie",
    to: "akademie",
    title: "German & Orientation Courses",
    description:
      "Discover German language and orientation courses tailored to your specific objectives at Weg2De Akademie. Explore our offerings based on the purpose of your journey to Germany and the DACH region. We offer individual and group courses, german course for companies, german for professionals and individuals seeking Apprenticeship opportunities. Discover more by clicking the link below.",
  },
];


export const portfolioServiceData = [

  {
    imageSrc: ApIcon,
    linkTo: "/contact-us",
    to: "/contact-us",
    title: "Visa and Immigration Services",
    Link:"",
    description:
    <ListWrapper>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Fast-Track Procedure (Work Visa)
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          Work Permit, EU-Blue Card
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Family Re-Union Visa 
        </TextItem>
      </IconWrapper>
    </ListItem>

    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Schengen Visa Application
        </TextItem>
      </IconWrapper>
    </ListItem>
    
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Residence Permit Renewal
        </TextItem>
      </IconWrapper>
    </ListItem>

    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Permanent Residency Application
        </TextItem>
      </IconWrapper>
    </ListItem>

    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
         General Visa Application Support
        </TextItem>
      </IconWrapper>
    </ListItem>
    
</ListWrapper>,
  },

  {
    imageSrc: AdmissionIcon,
    linkTo: "/study-in-germany",
    to: "/study-in-germany",
    title: "Study in Germany Package",
    description:
    <ListWrapper>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
              University & Course Search
              </TextItem>
            </IconWrapper>
          </ListItem>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
                University Application Submission
              </TextItem>
            </IconWrapper>
          </ListItem>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
                Visa Application Support
              </TextItem>
            </IconWrapper>
          </ListItem>

          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
                Travel Health Insurance
              </TextItem>
            </IconWrapper>
          </ListItem>
          
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
                Flight and Hotel reservation
              </TextItem>
            </IconWrapper>
          </ListItem>

          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
                Apartment search in Germany
              </TextItem>
            </IconWrapper>
          </ListItem>

          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
               Enrollment & Fee payment
              </TextItem>
            </IconWrapper>
          </ListItem>
          
    </ListWrapper>
  },

  {
    imageSrc: WorkIcon,
    linkTo: "/contact-us",
    to: "/contact-us",
    title: "Services for individuals in Germany",
    description:
    <ListWrapper>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Application for Kindergeld
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Application for Elterngeld
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          Residence Permit & Citizenship
        </TextItem>
      </IconWrapper>
    </ListItem>

    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          City Registration (Anmeldung)
        </TextItem>
      </IconWrapper>
    </ListItem>
    
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Apartment Search Support
        </TextItem>
      </IconWrapper>
    </ListItem>

    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Kindergarten & School Search
        </TextItem>
      </IconWrapper>
    </ListItem>

    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
         Translation of Foreign Documents
        </TextItem>
      </IconWrapper>
    </ListItem>
    
</ListWrapper>,
  },
  
  
  {
    imageSrc: RelocationIcon,
    linkTo: "/contact-us",
    to: "/contact-us",
    title: `Recognition of Foreign Qualification`,
    description:
    <ListWrapper>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        ZAB Statement of Comparability
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          IHK Recognition prof. Qualification
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          Translation of all documents
        </TextItem>
      </IconWrapper>
    </ListItem>

    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          Opportunity Card Application
        </TextItem>
      </IconWrapper>
    </ListItem>
    
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Application for Job Seeker Visa
        </TextItem>
      </IconWrapper>
    </ListItem>

    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Apartment search in Germany
        </TextItem>
      </IconWrapper>
    </ListItem>

    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
         Application Fee payment
        </TextItem>
      </IconWrapper>
    </ListItem>
    
</ListWrapper>,
  },

  
  {
    imageSrc: GermanyIcon,
    linkTo: "/job-search-workshop",
    to: "/job-search-workshop",
    title: "Job & Ausbildung Search Workshop",
    button: "Book a Session",
    description:
    
    <ListWrapper>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Job Market Insights
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        German CV Creation
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Job Portals in Germany
        </TextItem>
      </IconWrapper>
    </ListItem>
    

    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Application Techniques
        </TextItem>
      </IconWrapper>
    </ListItem>
    
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Interview Preparation 
        </TextItem>
      </IconWrapper>
    </ListItem>

    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Networking Tips
        </TextItem>
      </IconWrapper>
    </ListItem>

    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
         Translation of Foreign Documents
        </TextItem>
      </IconWrapper>
    </ListItem>
    
</ListWrapper>,
  },
  {
    imageSrc: TravelIcon,
    linkTo: "/contact-us",
    to: "/contact-us",
    title: "German Insurances",
    
    description:
    <ListWrapper>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        German Public Health Insurance
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        German Private Health Insurance
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        German Household Insurance
        </TextItem>
      </IconWrapper>
    </ListItem>

    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        German Life Insurance 
        </TextItem>
      </IconWrapper>
    </ListItem>
    
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        German Travel Insurance
        </TextItem>
      </IconWrapper>
    </ListItem>

    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Personal liability Insurance
        </TextItem>
      </IconWrapper>
    </ListItem>

    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
         German Legal Insurance
        </TextItem>
      </IconWrapper>
    </ListItem>
    
</ListWrapper>,
  },
];


export const servicesCardData = [
  {
    imageSrc: EntwicklerIcon,
    title: "Immigration",
    description:
    <ListWrapper>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
              Visaberatung & Antrag
              </TextItem>
            </IconWrapper>
          </ListItem>

          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
              Abschlussanerkennung
              </TextItem>
            </IconWrapper>
          </ListItem>
          
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
              Antrag auf Aufenthaltstitel
              </TextItem>
            </IconWrapper>
          </ListItem>
          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
              Flug, Reiseversicherung & Pick-up
              </TextItem>
            </IconWrapper>
          </ListItem>


          <ListItem>
            <IconWrapper>
              <Icon>
                <HiBadgeCheck size={20} />
              </Icon>
              <TextItem>
                Beschleunigtes Fachkräfte Verfahren
              </TextItem>
            </IconWrapper>
          </ListItem>
          
    </ListWrapper>

  },
  {
    imageSrc: MigrationIcon,
    title: "Relocation",
    description:
    <ListWrapper>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Settling-in
        </TextItem>
      </IconWrapper>
    </ListItem>
    
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Wohnungssuche
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Familiennachzug
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Kindergarten und Schule Suche 
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Orientation Tour
        </TextItem>
      </IconWrapper>
    </ListItem>
</ListWrapper>
  },
  {
    imageSrc: OnlineGermanIcon,
    title: "Intercultural Training",
    description:
    <ListWrapper>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Deutsch als Fremdsprache
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Arbeiten in Deutschland
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Leben in Deutschland 
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        Fit für die Berufsausbildung
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          German for Professionals
        </TextItem>
      </IconWrapper>
    </ListItem>
</ListWrapper>
  },
 /* {{
    imageSrc: SchulabsolventIcon,
    title: "Auszubildenden",
    description:
    <ListWrapper>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
        <b>Abschl.</b>: Schulabschluss 
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          <b>Leidenschaft & Interesse</b>
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          <b>Deutsch</b>: mind. B1 - B2
        </TextItem>
      </IconWrapper>
    </ListItem>
    <ListItem>
      <IconWrapper>
        <Icon>
          <HiBadgeCheck size={20} />
        </Icon>
        <TextItem>
          <b>Englisch</b>: mind. B2
        </TextItem>
      </IconWrapper>
    </ListItem>
</ListWrapper>
  },}*/
];

export const UniTestimonials = [
  {
    imageSrc:
      Luneburg,
    profileImageSrc:
      Lima,
    quote:
      "Weg2De made my dream of studying in Germany a reality! From helping me secure admission to Leuphana Universität to guiding me through the visa process, they were there every step of the way. Their team answered all my questions and ensured every document was perfect, which saved me so much stress. Now, I'm studying Public Economy, Law & Politics in one of the best academic environments, and I couldn't have done it without their support. Highly recommend their services!",
    customerName: "Halima",
    customerTitle: "Public Economy, Law & Politics – Leuphana Universität"
  },
  {
    imageSrc:
      Erfurt,
    profileImageSrc:
      Naya,
    quote:
      "I’m so grateful to Weg2De for helping me secure admission to Universität Erfurt. Their guidance throughout the application process and their expertise in handling the visa application were amazing. They made everything smooth and stress-free, which allowed me to focus on preparing for my studies. I’m now pursuing my passion for History in a vibrant and culturally rich environment, thanks to their support. Weg2De truly delivers on their promise!",
    customerName: "Naya",
    customerTitle: "History M.A. Universität Erfurt",
  },
];
export const defaultTestimonials = [
  {
    imageSrc:
      "https://framerusercontent.com/images/d8OnC0Wf4TmAV0ZmW6k9Q1rau8.jpg?scale-down-to=1024",
    profileImageSrc:
      "https://framerusercontent.com/images/d8OnC0Wf4TmAV0ZmW6k9Q1rau8.jpg?scale-down-to=1024",
    quote:
      "Dank Weg2De konnten wir einen hochqualifizierten Koch aus Nigeria erfolgreich nach Deutschland holen. Das Team hat uns durch das Beschleunigtes Fachkräfteverfahren geführt, den Visumantrag unterstützt und unseren neuen Mitarbeiter bis zur Ankunft in Deutschland begleitet. Der gesamte Prozess war professionell und effizient – wir sind begeistert!",
    customerName: "Seun",
    customerTitle: "Geschäftsführerin Yajee Restaurant",
  },
  {
    imageSrc:
      "https://th.bing.com/th/id/R.a52cfb7503c4ece7cd2532d7620e2518?rik=mdynDmx39JK9Rg&pid=ImgRaw&r=0",
    profileImageSrc:
      "https://th.bing.com/th/id/R.a52cfb7503c4ece7cd2532d7620e2518?rik=mdynDmx39JK9Rg&pid=ImgRaw&r=0",
    quote:
      "Weg2De hat uns geholfen, eine Fachkraft aus der Türkei nach Deutschland zu bringen. Durch ihre Unterstützung beim Anerkennungsverfahren und die individuelle Betreuung arbeitet unser MTA-F nun erfolgreich bei der Charité. Die Expertise und das Engagement von Weg2De sind unvergleichlich!",
    customerName: "Diana",
    customerTitle: "HR-Mitarbeiterin Berlin",
  },
];

export const studentTestimonials = [
  {
    imageSrc:
      BisolaCert,
    profileImageSrc:
      Bisola,
    quote:
      "I loved the interactive teaching pattern. The teacher was patient and engaging, always ready to answer questions and carry us along. I needed B2 Certificate to finalise my Ausbildung contract and I am so happy I can finally sign it.",
    customerName: "Bisola",
    customerTitle: "German B2 Student from Düsseldorf",
  },
  {
    imageSrc:
      JoshCert,
    profileImageSrc:
      "https://user-images.trustpilot.com/612337b150eb5100126a1e30/73x73.png",
    quote:
      "The class was interesting and the teacher is always ready to guide you through when needed. I recomend Weg2De to anyone who wants to have a good experience learning the German language",
    customerName: "Josh",
    customerTitle: "German A1 Student from Munich",
  },
];


export const UniFaqs = [
  {
    question: "What relocation services does Weg2De provide?",
    answer:
      "We assist individuals and families with Job relocation, Ausbildung, University,  visa & residence permit applications, housing search, city orientation, registration processes, and cultural integration.",
  },
  {
    question: "Do I need to know German to study in Germany?",
    answer:
      "Many universities offer programs in English, especially at the master’s level. However, some bachelor’s programs and daily life in Germany often require at least a B1 or B2 level in German. We can help you with language preparation to meet these requirements.",
  },
  {
    question: "How long does the application process take?",
    answer:
      "The application process can take anywhere from 3–6 months, including securing admission, obtaining a visa, and preparing for departure. Starting early ensures a smooth journey.",
  },
  {
    question: "Can I work while studying in Germany?",
    answer:
      "Yes! International students can work up to 120 full days or 240 half days per year. This helps students gain experience and cover living expenses",
  },
  {
    question: "Are there scholarships available for international students?",
    answer:
      "Yes, organizations like DAAD and universities offer a variety of scholarships. Our team can help you explore and apply for options that suit your needs.",
  },

  {
    question: "Where can I learn German?",
    answer:
      "We offer Online German Courses through Weg2De Akademie for individuals and companies",
  },
  {
    question: "Are Weg2De Akademie courses Online or offline?",
    answer:
      "We currently offer only Online courses to make German learning easy for everyone ",
  },

];

export const homepageFaqs = [
  {
    question: "What relocation services does Weg2De provide?",
    answer:
      "We assist individuals and families with visa applications, housing search, city orientation, registration processes, and cultural integration.",
  },
  {
    question: "What HR consulting services does Weg2De offer?",
    answer:
      "We specialize in recruiting international talent, onboarding support, and managing compliance with German labor laws.",
  },
  {
    question: "Can you help with onboarding international employees?",
    answer:
      "Yes, we support HR teams with cultural training, orientation sessions, and relocation assistance for new hires.",
  },
  {
    question: "Do you assist with work visas for international employees?",
    answer:
      "Yes, we handle all aspects of work visa applications for employees relocating to Germany.",
  },
  {
    question: "What kind of career support do you offer?",
    answer:
      "We offer personalized career advice, including resume building, interview preparation, and job search strategies tailored to the German job market. We also help connect you with potential employers and opportunities that align with your skills and experience.",
  },

  {
    question: "Where can I learn German?",
    answer:
      "We offer Online German Courses through Weg2De Akademie for individuals and companies",
  },
  {
    question: "Are Weg2De Akademie courses Online or offline?",
    answer:
      "We currently offer only Online courses to make German learning easy for everyone ",
  },

];

export const teamData = [
 
  {
    id: 1,
    imageSrc: IshaqIMG,
    role: "Founder & CEO",
    name: "Ishaq Kokumo",
    bio: "Ishaq is an IT professional with 7+ years of experience in migration services, helping individuals and companies settle in Germany. Fluent in English, German, and Yoruba, he connects with diverse clients. His passion for bridging cultural and language gaps drives his efforts to simplify the migration process.",
    mail: "mailto:ishaq.kokumo@weg2de.com",
    linkedInUrl: "https://www.linkedin.com/in/ishaqkokumo",
  },
 /* {
    id: 2,
    imageSrc: RANA,
    role: "Board Member",
    name: "Rana Atmaca",
    bio: "Rana, a Board Member at Weg2De, holds a B.Sc. in Audiology and has 5+ years of experience with companies in Türkiye and Germany. She volunteers with Weg2De to support audiology clients and advises the company on how best to serve Turkish-speaking customers. Rana is fluent in English, German, and Turkish.",
    mail: "mailto:info@weg2de.com",
    linkedInUrl: "https://www.linkedin.com/in/rana-atmaca-a21620190",
  },}
  /*
  {
    id: 3,
    imageSrc: Grace,
    role: "Admin. Assistant",
    name: "Grace Akinruli",
    bio: "Grace, a digital marketer with a B.Sc., she supports Weg2De and the CEO by strengthening the company's online presence and marketing strategies. Fluent in English and Yoruba, with some knowledge of German, she helps expand Weg2De’s reach to diverse audiences. Got questions about our German Courses? ",
    mail: "mailto:grace.akinruli@weg2de.com",
    linkedInUrl: "https://www.linkedin.com/company/weg2de",
  },
  
  {
    id: 4,
    imageSrc: Allaa,
    role: "German Instructor",
    name: "Allaa Taha",
    bio: "Allaa is a First Class German Language Graduate with over 8 years of experience in teaching German and has German Teaching Certifications from the Goethe Institut. She also has the BAMF-Zulassung which authorizes her to deliver German Language Lessons in Germany.",
    mail: "mailto:info@weg2de.com",
    linkedInUrl: "https://www.linkedin.com/company/weg2de",
  },
  {
    id: 5,
    imageSrc: Hosnny,
    role: "German Instructor",
    name: "Hossny Darweesh",
    bio: "Hosnny is a German Language Graduate from the prestigeous Al-Azar University, with over 8 years of experience and German Teaching Certifications from the Goethe Institut. He has the BAMF-Zulassung which authorizes him to give German Language Lessons in Germany.",
    mail: "mailto:info@weg2de.com",
    linkedInUrl: "https://www.linkedin.com/company/weg2de",
  },
  
  {
    id: 6,
    imageSrc: RANA,
    role: "Co-founder & HR",
    name: "John Doe",
    bio: "lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur adip",
    mail: "saheedadewaleshittu@gmail.com",
    linkedInUrl: "https://linkedin.com/in/saheed-shittu-56a16789",
  },*/
  
];
export const germanCoursesData = [
  {
    id: 1,
    imgSrc: OnlineLearningIMG,
    name: "German Online Group Course",
    levels: "A1, A2, B1, B2",
    duration: "8 Weeks",
    desc: "Our German Online Group Course has four levels: A1, A2, B1, and B2. Each level includes modules on speaking, listening, reading, and writing. Small class sizes ensure personalized attention and plenty of practice.",
    location: "Online Live Sessions",
    linkTo: "/german-online-group-course",
  },

  {
    id: 2,
    name: "Individual Courses",
    imgSrc: IndividualIMG,
    levels: "A1, A2, B1, B2",
    duration: "Varies",
    desc: "Our Individual Courses provide personalized German language training, ideal for both individuals and corporate clients. Tailored to your specific needs, our sessions help you achieve your language goals efficiently.",
    location: "",
    linkTo: "/individual-courses",
  },

  {
    id: 3,
    name: "German for Professionals",
    imgSrc: ProfessionalIMG,
    levels: "A1 - B2",
    duration: "6 - 9 Months",
    desc: "Our six-month German course which spans from A1 to B1 (or A1 to B2) and is tailored for IT, healthcare, engineering, and other professionals to build industry-specific language skills. Enhance your employment chances.",
    location: "Online Live Sessions",
    linkTo: "/german-for-professionals",
  },

];

export const careerCoachingData = [
  {
    id: 1,
    name: "Job Search Workshop",
    imgSrc: CareerIMG,
    levels: "Multiple Sessions",
    duration: "Weekly",
    desc: "Our Job Search Workshop equips you with the skills and strategies needed to succeed in the German job market. From crafting a standout resume to mastering interview techniques, this workshop is designed to help you land your desired job.",
    location: "Online Live Sessions",
    linkTo: "/job-search-workshop",
  },
  {
    id: 2,
    name: "Working & Living in Germany",
    imgSrc: EntwicklerIcon,
    levels: "Multiple Sessions",
    duration: "Weekly",
    desc: "Our Working & Living in Germany course prepares you for life in Germany by covering essential cultural insights, and practical guidance. Whether you're moving for work or study, this course equips you with the tools to navigate everyday life confidently.",
    location: "Online Live Sessions",
    linkTo: "/work-and-live-in-germany",
  },
  {
    id: 3,
    name: "Apprenticeship Program",
    imgSrc: WorkIcon,
    levels: "A1 - B2",
    duration: "9 Months",
    desc: "The Weg2De Apprenticeship Program is a comprehensive and meticulously structured preparatory program, designed to equip participants with the essential skills and knowledge required for a successful transition into the German workforce.",
    location: "Online Live Sessions",
    linkTo: "/apprenticeship-program",
  },
];

export const courseFaqs = [

  {
    question: "What does Weg2De do?",
    answer:
      "At Weg2De, we specialize in providing comprehensive relocation and immigration services to ensure a seamless transition for individuals, families, and organizations. Whether you’re a professional relocating for work, a family starting a new chapter abroad, or an HR team managing international hires, we’re here to support you every step of the way.",
  },
  {
    question:
      "What is the minimum number of participants required for the course to take place?",
    answer: "The minimum number of participants required for the course is 5.",
  },
  {
    question: "What happens if the minimum number of participants is not met?",
    answer:
      "If the minimum number of participants is not met, the course will not take place. In such a case, we will do our best to offer you an alternative option in good time. If you do not wish to accept the suggested alternative, any payments already made will be promptly refunded.",
  },
  {
    question: "Is there a placement test for the course?",
    answer:
      "Yes, there is a placement test to ensure you are placed in the appropriate class.",
  },
  {
    question: "What is the advantage of learning German with Weg2De Akademie?",
    answer:
      "We provide a structured curriculum, experienced instructors, and an interactive online platform that ensures effective learning",
  },
  {
    question: "What German language courses does Weg2De Akademie offer?",
    answer:
      "We offer online group courses for levels A1, A2, B1, and B2, covering speaking, listening, reading, and writing skills",
  },
  {
    question: "How are the online German courses structured?",
    answer:
      "Our courses include live sessions, interactive exercises, and personalized feedback, ensuring a comprehensive learning experience.",
  },
  {
    question: "What technical requirements are needed for online classes?",
    answer:
      "A stable internet connection, a computer or tablet, and a headset are recommended for optimal participation.",
  },
  {
    question: "Do you provide resources for self-study in addition to live classes?",
    answer:
      "Yes, our platform offers self-study materials, including videos, quizzes, and reading resources, for additional practice.",
  },
  {
    question: "Do you provide resources for self-study in addition to live classes?",
    answer:
      "Yes, our platform offers self-study materials, including videos, quizzes, and reading resources, for additional practice.",
  },
  {
    question: "Where can I find your refund policy?",
    answer:
      "https://weg2de.com/terms-and-conditions or in the Footer"
  },

  {
    question: "What should I do if I have more questions or need assistance?",
    answer: customAns,
  },



];

export const courseFeeData = [
  { id: 1, level: "A1.1+A1.2", module: "incl. e-Books", price: "399", currency: "€", duration: "(8 Weeks)", start: " > 1st April 2025", morningsessions: " > 9.00am - 11.30am CET", amdays: " > Monday, Wednesday, Friday", eveningsessions: " > 7.00pm - 9.30pm CET", pmdays: " > Monday, Wednesday, Friday"},
  { id: 1, level: "A2.1+A2.2", module: "incl. e-Books", price: "399", currency: "€", duration: "(8 Weeks)", start: " > 1st April 2025", morningsessions: " > 9.00am - 11.30am CET", amdays: " > Monday, Wednesday, Thursday", eveningsessions: " > 7.00pm - 9.30pm CET", pmdays: " > Monday, Wednesday, Friday"},
  { id: 1, level: "B1.1+B1.2", module: "incl. e-Books", price: "449", currency: "€", duration: "(10 Weeks)", start: " > 1st April, 2025", morningsessions: " > 9.00am - 11.30am CET", amdays: " > Sun./Mon., Tuesday, Thursday", eveningsessions: " > 7.00pm - 9.30pm CET", pmdays: " > Sun./Mon., Tuesday, Thursday"},
  { id: 1, level: "B2.1+B2.2", module: "incl. e-Books", price: "499", currency: "€" , duration: "(12 Weeks)", start: " > 14th April, 2025", morningsessions: " > 9.00am - 11.30am CET", amdays: " > 2x a week", eveningsessions: " > 7.00pm - 9.30pm CET", pmdays: " > 2x a week "},
];

export const IndcourseFeeData = [
  { id: 1, level: "1-1 course", module: "incl. e-Books", price: "29", currency: "€", hr: "/h", duration: "(8 Weeks)", },
  { id: 1, level: "Group of 2", module: "incl. e-Books", price: "399", currency: "€", duration: "(8 Weeks)", },
  { id: 1, level: "B1.1+B1.2", module: "incl. e-Books", price: "449", currency: "€", duration: "(10 Weeks)", },
  { id: 1, level: "B2.1+B2.2", module: "incl. e-Books", price: "499", currency: "€" , duration: "(12 Weeks)", },
];

export const G4pcourseFeeData = [
  { id: 1, level: "(G4P) A1 - B1", module: "incl. e-Books", price: "1350", currency: "€", duration: "(7 Months)", },
  { id: 1, level: "(G4P) A1 - B2", module: "incl. e-Books", price: "1750", currency: "€", duration: "(9 Months)", },
  // { id: 1, level: "B1", module: "A1 - B2", price: "1750", currency: "€" },
  // { id: 1, level: "B2", module: "B2.1+B2.2", price: "450", currency: "€" },
];

export const AusbildungFeeData = [
  { id: 1, level: "(WAPP) A1 - B1", module: "incl. e-Books", price: "1250", currency: "€", duration: "(7 Months)", },
  { id: 1, level: "(WAPP) A1 - B2", module: "incl. e-Books", price: "1650", currency: "€",  duration: "(9 Months)", }, 
  // { id: 1, level: "B1", module: "A1 - B2", price: "1750", currency: "€" },
  // { id: 1, level: "B2", module: "B2.1+B2.2", price: "450", currency: "€" },
];

export const CandidateCustomerCount = [
  {id: 1,  startCount: 0, endCount: 50, countDuration: 15,  title:"Students"},
  {id: 2,  startCount: 0, endCount: 25, countDuration: 15,  title:"Graduates"},
  {id: 3,  startCount: 0, endCount: 2, countDuration: 15,  title:"Semesters"},
  {id: 4,  startCount: 0, endCount: 10, countDuration: 15,  title:"Courses"},
]

export const CourseCustomerCount = [
  {id: 1,  startCount: 0, endCount: 20, countDuration: 15,  title:"Students"},
  {id: 2,  startCount: 0, endCount: 5, countDuration: 15,  title:"Graduates"},
  {id: 3,  startCount: 0, endCount: 1, countDuration: 15,  title:"Semester"},
  {id: 4,  startCount: 0, endCount: 3, countDuration: 15,  title:"Courses"},
]

export const hiringStepsData = [
  {id: 1, imgSrc:OnlineGermanIcon, title: "Anforderungen definieren", desc: "Teilen Sie uns Ihre Bedürfnisse und die Details der offenen Stelle mit. Wir erstellen Gemeinsam ein klares Anforderungsprofil, um die passenden Kandidaten für Ihr Unternehmen zu finden."},
  {id: 2, imgSrc:OnlineGermanIcon, title: "Empfehlung von Kandidaten", desc: "Wir starten eine gezielte Suche und Vorauswahl. Sie erhalten eine Auswahl qualifizierter Kandidaten, die sowohl fachlich als auch zur Ihr Unternehmenskultur passen."},
  {id: 3, imgSrc:OnlineGermanIcon, title: "Interview, Auswahl & Training", desc: "Wir Organisieren die Vorstellungsgespräche und Sie wählen den besten Kandidaten aus. Bei Bedarf bieten wir Deutschkurse und professionelles Training zur Vorbereitung an."},
  {id: 4, imgSrc:OnlineGermanIcon, title: "Visumantrag & Einreise", desc: "Wir unterstützen Sie beim gesamten Visumsprozess, übernehmen die Kommunikation mit Behörden und erleichtern den reibungslosen Übergang Ihre neue mitarbeiter nach Deutschland."},
  {id: 5, imgSrc:VisaIcon, title: "Integration & Onboarding", desc: "Wir begleiten den Integrationsprozess, unterstützen bei Wohnungssuche, Behördengängen und organisieren Deutschkurse sowie weiteres Training für einen erfolgreichen Start."},
];

export const generalFaqs = [
  {
    question: "What does Weg2De do?",
    answer:
      "At Weg2De, we specialize in providing comprehensive relocation and immigration services to ensure a seamless transition for individuals, families, and organizations. Whether you’re a professional relocating for work, a family starting a new chapter abroad, or an HR team managing international hires, we’re here to support you every step of the way.",
  },
  {
    question:
      "What is the minimum number of participants required for the course to take place?",
    answer: "The minimum number of participants required for the course is 5.",
  },
  {
    question: "What happens if the minimum number of participants is not met?",
    answer:
      "If the minimum number of participants is not met, the course will not take place. In such a case, we will do our best to offer you an alternative option in good time. If you do not wish to accept the suggested alternative, any payments already made will be promptly refunded.",
  },
  {
    question: "Is there a placement test for the course?",
    answer:
      "Yes, there is a placement test to ensure you are placed in the appropriate class.",
  },
  {
    question: "What is the advantage of learning German with Weg2De Akademie?",
    answer:
      "We provide a structured curriculum, experienced instructors, and an interactive online platform that ensures effective learning",
  },
  {
    question: "What German language courses does Weg2De Akademie offer?",
    answer:
      "We offer online group courses for levels A1, A2, B1, and B2, covering speaking, listening, reading, and writing skills",
  },
  {
    question: "How are the online German courses structured?",
    answer:
      "Our courses include live sessions, interactive exercises, and personalized feedback, ensuring a comprehensive learning experience.",
  },
  {
    question: "What technical requirements are needed for online classes?",
    answer:
      "A stable internet connection, a computer or tablet, and a headset are recommended for optimal participation.",
  },
  {
    question: "Do you provide resources for self-study in addition to live classes?",
    answer:
      "Yes, our platform offers self-study materials, including videos, quizzes, and reading resources, for additional practice.",
  },
  {
    question: "Do you provide resources for self-study in addition to live classes?",
    answer:
      "Yes, our platform offers self-study materials, including videos, quizzes, and reading resources, for additional practice.",
  },
  {
    question: "What should I do if I have more questions or need assistance?",
    answer: customAns,
  },

  {
    question: "Do you offer support for learning the German language as part of relocation? ",
    answer:
      "Yes, our relocation packages include options for enrolling in German language courses to ease your transition.",
  },
  {
    question: "Is job search support included in your relocation services?",
    answer:
      "While we don’t guarantee jobs, we provide guidance on job search strategies, CV optimization, and interview preparation in Germany.",
  },
  {
    question: "Do you assist with visa and work permit applications?",
    answer:
      "Absolutely! We guide you through every step of the visa and work permit application process to ensure a smooth transition.",
  },
  {
    question: "What type of housing support do you offer?",
    answer:
      "We help clients find suitable accommodations, whether it's short-term rentals or long-term housing solutions in Germany.",
  },
  {
    question: "Can Weg2De guide me on how to open a bank account in Germany?",
    answer:
      "Yes, we guide you on opening a local bank account and navigating German financial systems.",
  },
  {
    question: "How long does the relocation process typically take?",
    answer:
      "The timeline varies based on visa processing, housing availability, and individual circumstances.",
  },
  {
    question: "Can you assist with obtaining a visa for Germany?",
    answer:
      "Yes, we provide guidance and support throughout the visa application process, ensuring you have all the required documents and meet the necessary criteria to successfully obtain a visa for Germany.",
  },
  {
    question: "What kind of career support do you offer?",
    answer:
      "We offer personalized career advice, including resume building, interview preparation, and job search strategies tailored to the German job market. We also help connect you with potential employers and opportunities that align with your skills and experience.",
  },
  {
    question: "What HR consulting services does Weg2De offer?",
    answer:
      "We specialize in recruiting international talent, onboarding support, and managing compliance with German labor laws.",
  },
  {
    question: "How do you ensure compliance with German labor laws?",
    answer:
      "Our team provides expert guidance to ensure your company meets all German labor law requirements, including contracts and workplace regulations.",
  },
  {
    question: "Do I need to know German to study in Germany?",
    answer:
      "Many universities offer programs in English, especially at the master’s level. However, some bachelor’s programs and daily life in Germany often require at least a B1 or B2 level in German. We can help you with language preparation to meet these requirements.",
  },
  {
    question: "How long does the application process take?",
    answer:
      "The application process can take anywhere from 3–6 months, including securing admission, obtaining a visa, and preparing for departure. Starting early ensures a smooth journey.",
  },
  {
    question: "Can I work while studying in Germany?",
    answer:
      "Yes! International students can work up to 120 full days or 240 half days per year. This helps students gain experience and cover living expenses",
  },
  {
    question: "Are there scholarships available for international students?",
    answer:
      "Yes, organizations like DAAD and universities offer a variety of scholarships. Our team can help you explore and apply for options that suit your needs.",
  },

  {
    question: "Are Weg2De Akademie courses Online or offline?",
    answer:
      "We currently offer only Online courses to make German learning easy for everyone ",
  },

];