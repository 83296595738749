import { Helmet } from "react-helmet-async";
import { defaultTestimonials, servicesCardData } from "data/dummyData";
//import AboutCompany from "./AboutCompany";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import CompanyLanding from "./Landing";
import CompanyServicesPage from "./CompanyServices";
import FooterPage from "components/Footer";
//import HiringSteps from "./HiringSteps";
import React, {useEffect} from "react";
import SEO from "components/seo/SEO";
import AppointmentBooking from "./AppointmentBooking";
import CompanyTestimonialPage from "./CompanyTestimonial";

function CompaniesPage() {

  useEffect(() => {
    document.title = "Weg2De Relocation Agentur | Professionelle Relocation Services für Ihre Mitarbeiter | Visas, Arbeits- und Aufenthaltserlaubnis uvm. |  Weg2De";
  }, []);
  
  return (
    <AnimationRevealPage>
      <SEO
        title="Weg2De Relocation Agentur | Professionelle Relocation Services für Ihre Mitarbeiter | Weg2De"
        description="Suchen Sie eine zuverlässige Relocation-Agentur für Ihre Mitarbeiter? Weg2De bietet erstklassige Dienstleistungen, um den Umzug nach Deutschland zu erleichtern: Unterstützung bei Visumanträgen, Aufenthaltserlaubnissen, Familiennachzug und dem beschleunigten Fachkräfteverfahren. Setzen Sie auf unsere Expertise, um Ihren internationalen Talenten einen erfolgreichen Start zu ermöglichen."
        name="Weg2De - For Companies - Professionelle Relocation Services für Ihre Mitarbeiter"
        keyword="Beantragung von Visas, Reiseversicherung, Hotel & Flug, Arbeitserlaubnis, EU-Blaue Karte, Anerkennungsverfahren,Beschleunigtes Fachkräfte Verfahren
        Anmeldung in eine Wohnung,Arbeits- und Aufenthaltserlaubnis,Antrag auf Kinder- und Elterngeld,Beschleunigtes Fachkräfte Verfahren,Eröffnung eines Bankkontos
      ,Kurz- und Langzeitunterkünfte,Übersetzung und Beglaubigung, Kindergarten und Schule Suche,Kranken- & sonstige Versicherungen,
      Relocation Agentur in Wuppertal, Relocation Agentur in Düsseldorf, Relocation Agentur in Köln"
        type="website"
      />
            <Helmet>
              <title>Weg2De Relocation Agentur | Professionelle Relocation Services für Ihre Mitarbeiter | Weg2De</title>
              <meta property="og:title" content="Weg2De Relocation Agentur | Professionelle Relocation Services für Ihre Mitarbeiter | Weg2De" />
              <meta property="og:description" content="Suchen Sie eine zuverlässige Relocation-Agentur für Ihre Mitarbeiter? Weg2De bietet erstklassige Dienstleistungen, um den Umzug nach Deutschland zu erleichtern: Unterstützung bei Visumanträgen, Aufenthaltserlaubnissen, Familiennachzug und dem beschleunigten Fachkräfteverfahren. Setzen Sie auf unsere Expertise, um Ihren internationalen Talenten einen erfolgreichen Start zu ermöglichen." />
              <meta property="og:url" content="https://weg2de.com/for-companies" />
            </Helmet>
      <CompanyLanding />
      <CompanyServicesPage id='services' heading="DIENSTLEISTUNGEN" data={servicesCardData} />
      <CompanyTestimonialPage 
      subheading="Das sagen unsere Kunden"
      heading="KUNDENERFAHRUNG"
      testimonials={defaultTestimonials}/>
      
      {/*<AboutCompany subheading="ProFit Hörakustik" />
     
      <HiringSteps
        heading="Curious? Get in touch today!"
        data={hiringStepsData}
      />*/}
      
      <AppointmentBooking id='contact'
      heading="MÖCHTEN SIE MEHR ERFAHREN?"/>
      
      <FooterPage />
    </AnimationRevealPage>
  );
}

export default CompaniesPage;
