import { HighlightedText, MainHeading } from "components/misc/Headings";

import { HiBadgeCheck } from "react-icons/hi";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";

const LeftColumn = tw.section` md:pr-5 relative flex-1`;
const RightColumn = tw.section`md:pl-5 relative flex-1`;
const Pill = tw.span`font-serif md:tracking-widest px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const IconWrapper = tw.div`flex items-center text-primary-500`;
const TwoColumn = tw.section`mt-5  flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;

const ListItem = tw.li`text-primary-500 mt-5 text-left  text-sm md:text-base lg:text-lg font-light mb-3`;
const TextItem = tw.p`text-gray-500`;
const Icon = tw.div`hidden md:block mr-2 `;
const ListWrapper = tw.ul`ml-3 md:ml-0 list-disc md:list-none text-primary-500`;
const Container = tw.section`md:mt-10 max-w-screen-xl mx-auto`;

const OverviewSection = ({ heading, highlight }) => {
  return (
    <Container>
      <MainHeading>
        {heading}
        <HighlightedText>{highlight} </HighlightedText>
      </MainHeading>
      <TwoColumn>
        <LeftColumn>
          <Pill>PROGRAM DETAILS</Pill>

          <SectionDescription>
            Experience personalized, one-on-one German language learning with
            our Online Individual Course, tailored for adult learners from A1
            (beginner) to B2 (upper-intermediate). Whether you’re starting from
            scratch or aiming to refine your skills, this course adapts to your
            specific needs, goals, and pace, providing an effective and flexible
            way to master German.
          </SectionDescription>
          <SectionDescription>
            <b>Course Structure: </b>Our Individual Course covers all language aspects:
            speaking, listening, reading, and writing. Your dedicated instructor
            will create a customized learning plan that aligns with your
            proficiency level and objectives, ensuring you make steady and
            targeted progress.
          </SectionDescription>
          <SectionDescription>
            Sign up for our German Online Individual Course and embark on a
            personalized language learning journey. Whether for personal
            development, travel, or professional growth, mastering German with
            the flexibility and focus of one-on-one lessons is the key to your
            success!
          </SectionDescription>

          <Pill>KEY FACTS</Pill>
          <ListWrapper>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>1-1 Pricing</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                Our pay-as-you go pricing starts at <b>32€ per 60 minutes</b>.  <br/><br/><li>5 hours lesson credit = 150€ (30€/hr)</li> <li>10 hours lesson credit = 290€ (29€/hr)</li> <li>20 hours lesson credit = 560€ (28€/hr)</li> <br/> *The credits are valid for one year and can be used anytime
              </SectionDescription>
            </ListItem>
            </ListWrapper>
        </LeftColumn>
        <RightColumn>
          <ListWrapper>
          <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Friends & Family Group Pricing</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                Our pay-as-you go GROUP pricing starts at <b>37€ per hour for two persons</b>. <br/><br/> <li>Group of 3 = 48€ /hr</li> <li>Group of 4 = 54€ /hr</li> <li>Group of 5 = 60€ /hr</li>
                <br/> *The credits are valid for one year and can be used anytime
              </SectionDescription>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>One-on-One Live Sessions</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                Benefit from personalized attention and direct interaction with
                an experienced native-speaking instructor. Engage in lessons
                tailored to your learning style and pace.
              </SectionDescription>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Customized Curriculum</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                Your instructor will design a course syllabus suited to your
                specific needs, whether you want to focus on conversational
                skills, grammar, business German, or exam preparation.
              </SectionDescription>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Flexible Scheduling</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                Choose lesson times that fit your schedule. Sessions can be
                rescheduled with prior notice, offering maximum convenience.
              </SectionDescription>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Multimedia Resources</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                Access a variety of learning materials, including videos, audio,
                and interactive exercises to supplement your lessons and enhance
                your learning experience.
              </SectionDescription>
            </ListItem>
            
          </ListWrapper>
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};

export default OverviewSection;
